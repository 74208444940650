import { computed, Ref } from "@vue/composition-api"

export enum Action {
  Delete,
  Edit,
  View,
}

export interface ActionProperties {
  disabled: boolean
}

export interface ActionMenuItem extends ActionProperties {
  action: Action
}

export interface ActionDescription {
  icon: string
  messageKey: string
}

export const actionDescription: Record<Action, ActionDescription> = {
  [Action.Delete]: {
    icon: "mdi-delete",
    messageKey: "delete",
  },
  [Action.Edit]: {
    icon: "mdi-pencil",
    messageKey: "edit",
  },
  [Action.View]: {
    icon: "mdi-eye",
    messageKey: "view",
  },
}

/**
 * create action menu item with given properties
 *
 * @param action      menu item action
 * @param disabled    flag, indicating whether the menu item should be disabled
 */
export function createActionMenuItem(
  action: Action,
  disabled = false
): ActionMenuItem {
  return {
    action,
    disabled,
  }
}

/**
 * the object, used to be able to provide action menu items and handle
 * selected actions
 */
interface UseAction<IdT> {
  /**
   * actions, available for for entries, mapped by entry ID (`IdT` ist the type of the entry key)
   */
  actions: Ref<Map<IdT, Array<ActionMenuItem>>>
  /**
   * the function to handle specified `action` for the entry, specified by it's ID
   *
   * @param id          entry ID
   * @param action      action need to be handled
   */
  onAction: (id: IdT, action: Action) => void
}

/**
 * prepare data to be used to create and handle action menus for specified `entries`
 *
 * @param entries           list of objects, which need to be handled with the aid of action menus
 * @param getKey            the function, which is used to construct the `key` (uniq ID) for each entry
 * @param actionsForItem    the function, which is used to define which actions are available for given entry
 * @param actionMap         the map of handler functions for actions, used as the map key.
 *                          The handler function gets the entry ID as an argument.
 */
export function useActions<ObjectsT, IdT>(
  entries: Ref<Array<ObjectsT> | undefined>,
  getKey: (it: ObjectsT) => IdT,
  actionsForItem: (it: ObjectsT) => Array<[Action, boolean?]>,
  actionMap: Map<Action, (id: IdT) => void>
): UseAction<IdT> {
  const actions = computed(() => {
    const actions: Map<IdT, Array<ActionMenuItem>> = new Map<
      IdT,
      Array<ActionMenuItem>
    >()
    entries?.value?.forEach((it: ObjectsT) => {
      const id = getKey(it)
      if (id !== undefined) {
        actions.set(
          id,
          actionsForItem(it).map((tuple) => createActionMenuItem(...tuple))
        )
      }
    })
    return actions
  })

  const onAction = (id: IdT, action: Action) => {
    const actionFunction = actionMap.get(action)
    if (actionFunction) {
      actionFunction(id)
    } else {
      console.warn(`Unknown action "${action}" for the entry "${id}".`)
    }
  }

  return { onAction, actions }
}
