





































import { computed, defineComponent, reactive, ref } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import { Client } from "@/_service/client"

import { useClients } from "@/_use/client"
import { useRouteTitle } from "@/_use/routeTitle"

import ActionMenu from "@/component/ActionMenu.vue"
import { Action, useActions } from "@/component/ActionMenu"
import SuccessAlert from "@/component/SuccessAlert.vue"
import BackendName from "@/component/BackendName.vue"
import Loader from "@/component/Loader.vue"

import ClientDeleteDialog from "./component/ClientDeleteDialog.vue"

export default defineComponent({
  name: "ClientList",

  components: {
    Loader,
    ActionMenu,
    ClientDeleteDialog,
    SuccessAlert,
    BackendName,
  },

  setup(_, { root }) {
    const { t } = useI18n(
      require.context("./", true, /ClientList\.[\w-]+\.(json|ya?ml)$/i)
    )

    useRouteTitle(() => t("title"))
    const { cached: clients, isLoading, refresh } = useClients()

    // delete dialog
    const isDeleteDialogVisible = ref(false)
    const nameOfClientToDelete = ref("")
    const idOfClientToDelete = ref(0)

    const successAlert = reactive<{
      value: boolean
      msgKey?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: { [key: string]: any }
    }>({
      value: false,
      msgKey: undefined,
      params: undefined,
    })

    const onDeleted = (name: string) => {
      successAlert.msgKey = "deleteDialog.success"
      successAlert.params = { name }
      successAlert.value = true
      refresh()
    }

    // table properties
    const headers = computed(() => {
      return [
        {
          text: t("header.id"),
          align: "right",
          value: "id",
        },
        {
          text: t("header.name"),
          value: "name",
        },
        {
          text: t("header.backend"),
          value: "backendId",
        },
        {
          text: t("header.actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ]
    })

    const actionMap = new Map<Action, (id: number) => void>()
    actionMap.set(Action.Delete, (id: number) => {
      const client = clients.value?.find((c) => c.id === id)
      if (client && client.id) {
        nameOfClientToDelete.value = client.name
        idOfClientToDelete.value = client.id
        isDeleteDialogVisible.value = true
      } else {
        console.warn(
          "client with this id could not be found. Deletion not processed."
        )
      }
    })

    actionMap.set(Action.Edit, (id: number) => {
      root.$router.push({ name: "client.update", params: { id: `${id}` } })
    })

    const { onAction, actions } = useActions<Client, number>(
      clients,
      (client: Client) => client.id as number,
      () => [/*[Action.View, true],*/ [Action.Edit], [Action.Delete]],
      actionMap
    )

    return {
      actions,
      clients,
      headers,
      isLoading,
      onAction,
      t,
      onDeleted,
      successAlert,
      isDeleteDialogVisible,
      nameOfClientToDelete,
      idOfClientToDelete,
    }
  },
})
