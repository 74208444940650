



















import { computed, defineComponent } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import BaseDialog from "@/component/BaseDialog.vue"
import Loader from "@/component/Loader.vue"

export default defineComponent({
  name: "DeleteDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  components: { BaseDialog, Loader },

  setup(props, { emit }) {
    const { t } = useI18n()
    const dialog = computed({
      get() {
        return props.value
      },
      set(newVal) {
        emit("input", newVal)
      },
    })

    return {
      dialog,
      t,
    }
  },
})
