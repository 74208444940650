






































import { computed, defineComponent, PropType } from "@vue/composition-api"
import { useI18n } from "@/_i18n"
import {
  Action,
  actionDescription,
  ActionMenuItem,
  ActionProperties,
} from "@/component/ActionMenu"

interface MenuItem extends ActionMenuItem, ActionProperties {}

export default defineComponent({
  name: "ActionMenu",
  props: {
    items: {
      type: Array as PropType<Array<ActionMenuItem>>,
    },
  },
  setup({ items }, { emit }) {
    const { t } = useI18n(
      require.context("./", true, /ActionMenu\.[\w-]+\.(json|ya?ml)$/i)
    )

    const menuItems = computed(
      (): Array<MenuItem> =>
        items?.map((it: ActionMenuItem) => ({
          ...actionDescription[it.action],
          ...it,
        })) || []
    )

    const onAction = (action: Action) => {
      emit("action", action)
    }

    return { menuItems, onAction, t }
  },
})
