











import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { useApiCall } from "@/_use/apiCall"
import { ErrorType } from "@/_use/errorHandling"
import { deleteClient } from "@/_service/client"

import { useDialogUtils } from "@/component/Dialog.ts"

import DeleteDialog from "@/component/DeleteDialog.vue"

export default defineComponent({
  name: "ClientDeleteDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: Number,
      default: -1,
    },
    clientName: {
      type: String,
      default: "",
    },
  },

  components: { DeleteDialog },

  setup(props, { emit }) {
    const { t } = useI18n(
      require.context("../", true, /ClientList\.[\w-]+\.(json|ya?ml)$/i)
    )

    const { closeDialog, dialog } = useDialogUtils(props, emit)

    const cId = computed(() => props.clientId)

    const submittedParameter = reactive({ clientId: cId.value })

    watch(cId, (newValue) => {
      submittedParameter.clientId = newValue
    })

    const { isLoading, onSubmit, errorMessage, reset } = useApiCall(
      deleteClient,
      t,
      submittedParameter,
      () => {
        dialog.value = false
        emit("deleted", props.clientName)
      },
      {
        [ErrorType.ObjectState]: (error) => {
          if (error.errorParameters?.state === "has-users") {
            return { key: "errors.hasUsers" }
          } else if (error.errorParameters?.state === "has-mailboxes") {
            return { key: "errors.hasMailboxes" }
          }
        },
      }
    )

    return {
      dialog,
      t,
      isLoading,
      onSubmit,
      errorMessage,
      onCancel: () => closeDialog(reset),
    }
  },
})
