import { computed, Ref } from "@vue/composition-api"

export const useDialogUtils = (
  props: { value: boolean },
  emit: (event: string, ...args: unknown[]) => void
): { closeDialog: (cb?: () => void) => void; dialog: Ref<boolean> } => {
  const dialog = computed<boolean>({
    get() {
      return props.value
    },
    set(newVal) {
      emit("input", newVal)
    },
  })

  const closeDialog = (cb?: () => void) => {
    dialog.value = false
    if (cb instanceof Function) cb()
  }

  return {
    closeDialog,
    dialog,
  }
}
